import { Store } from 'pullstate';
import { getInitials } from 'utils/initials';
import api from 'services/api';
import { persistSessionValue, retrieveSessionValue } from 'services/local';
import { logger } from 'services/logs/logger';
import { LocalStorageKey } from 'utils/localstorage';
import {
  FILE_UPLOAD_MAXIMUM_WARNING,
  FILE_UPLOAD_MONTHLY_LIMIT_DEFAULT,
  FILE_UPLOAD_MONTHLY_LIMIT_INFINITE,
  MetadataStore,
} from './metadata';
import {
  FileType,
  filterFilesInProgress,
  formatFileErrorMessages,
  hasFilesInProgress,
  IndexType,
} from 'utils/file-utils';
import {
  appendToFileContexts,
  CurrentConversationStore,
  setFileContextFiles,
  setGptModel,
  setIndexContextIndexes,
} from './current-conversation';
import { setGlobalError } from './global-message';

export const NIL_UUID = '00000000-0000-0000-0000-000000000000';

type UserInfo = {
  name: string;
  email: string;
  initials: string;
};

type UserStoreType = {
  requestedAuth: boolean;
  isAuthed: boolean;
  userHasLoaded: boolean;
  apiConfig: any;
  personalFiles: FileType[];
  personalIndexes: IndexType[];
  hasPendingFiles: boolean;
  userInfo: UserInfo | null;
  agreedToTerms: boolean;
  viewedSplash: boolean;
  currentSession?: string;
  documentsRemaining: number;
  documentsUploaded: number;
  documentLimit?: number;
  currentMonthlyUserPromptsPerModel: any;
  maxMonthlyPrompts: any;
  exhaustedGptModels: string[];
  allPromptsExhausted: boolean;
};

const initialState: UserStoreType = {
  requestedAuth: false,
  isAuthed: false,
  userHasLoaded: false,
  apiConfig: null,
  personalFiles: [],
  personalIndexes: [],
  hasPendingFiles: false,
  userInfo: null,
  agreedToTerms: false,
  viewedSplash: false,
  currentSession: NIL_UUID,
  documentsRemaining: FILE_UPLOAD_MONTHLY_LIMIT_DEFAULT,
  documentsUploaded: FILE_UPLOAD_MONTHLY_LIMIT_DEFAULT,
  documentLimit: FILE_UPLOAD_MONTHLY_LIMIT_DEFAULT,
  currentMonthlyUserPromptsPerModel: {},
  maxMonthlyPrompts: {},
  exhaustedGptModels: [],
  allPromptsExhausted: false,
};

const FILE_POLLING_INTERVAL = 3000;

export const UserStore = new Store(initialState);

let fileInterval = {};

async function pollForFileUpdate(fileId: string) {
  const isPending = await getIsPersonalFilePending(fileId);
  if (isPending) {
    if (!fileInterval[fileId]) {
      logger.info(fileId + ' is pending');
      fileInterval[fileId] = setInterval(() => {
        pollForFileUpdate(fileId);
      }, FILE_POLLING_INTERVAL);
    }
  } else {
    logger.info(fileId + ' is no longer pending');
    clearInterval(fileInterval[fileId]);
    fileInterval[fileId] = undefined;
    await getPersonalFiles();

    appendToFileContexts([fileId]);
    const newStatus = await api.getFileInfo(fileId);
    if (
      newStatus.processingStatus === 'error' ||
      newStatus.processingStatus === 'rejected'
    ) {
      logger.error('File failed to upload', newStatus);
      setGlobalError(
        formatFileErrorMessages(newStatus.processingFailureComment, 'toast', {
          name: newStatus.displayFileName,
        }),
      );
    }
  }
}

UserStore.createReaction(
  (s) => s.personalFiles,
  (personalFiles, draft) => {
    const hasPending = hasFilesInProgress(personalFiles);
    draft.hasPendingFiles = hasPending;
    const pendingFiles = hasPending
      ? [...filterFilesInProgress(personalFiles)]
      : [];
    pendingFiles.forEach((file) => {
      pollForFileUpdate(file.id);
    });
  },
);

// Keeps the current conversation store apprised of the total files available
UserStore.createReaction(
  (s) => s.personalFiles,
  (personalFiles) => {
    setFileContextFiles(personalFiles);
  },
);

// Keeps the current conversation store apprised of the total indexes available
UserStore.createReaction(
  (s) => s.personalIndexes,
  (personalIndexes) => {
    setIndexContextIndexes(personalIndexes);
  },
);

// If the current selected GPT model is disabled, switch to the first available model
UserStore.createReaction(
  (s) => s.exhaustedGptModels,
  (exhaustedGptModels) => {
    const gptModels = MetadataStore.getRawState().gptModels;
    if (!gptModels?.length) {
      return;
    }
    const gptModel = CurrentConversationStore.getRawState().gptModel;
    if (!gptModel || exhaustedGptModels.includes(gptModel)) {
      const firstAvailableModel = getFallbackModelName();

      if (firstAvailableModel) {
        setGptModel(firstAvailableModel);
      }
    }
  },
);

export function getFallbackModelName() {
  return 'automatic';
}

function getExhaustedGptModelsByUser(userInfo: any) {
  return userInfo?.currentMonthlyUserPromptsPerModel
    ? Object.keys(userInfo.currentMonthlyUserPromptsPerModel).filter(
        (prompt) => {
          return (
            userInfo?.currentMonthlyUserPromptsPerModel[prompt] >=
            userInfo?.maxMonthlyPrompts[prompt]
          );
        },
      )
    : [];
}

export const loadUserLimits = () => {
  return api.getUserLimits().then((userInfo) => {
    const limit =
      userInfo.maxMonthlyFileUploads || FILE_UPLOAD_MONTHLY_LIMIT_INFINITE;
    UserStore.update((s) => {
      const gptModels = MetadataStore.getRawState().gptModels;
      const exhaustedGptModels = gptModels?.length // Only do this if GPT Models have loaded
        ? getExhaustedGptModelsByUser(userInfo)
        : [];
      s.exhaustedGptModels = exhaustedGptModels;
      s.allPromptsExhausted =
        Object.keys(userInfo?.currentMonthlyUserPromptsPerModel || {}).length >
          0 &&
        Object.keys(userInfo?.currentMonthlyUserPromptsPerModel || {})
          .length === exhaustedGptModels.length;
      s.documentsRemaining =
        limit !== FILE_UPLOAD_MONTHLY_LIMIT_INFINITE
          ? limit - userInfo.currentMonthlyFilesUploaded
          : FILE_UPLOAD_MONTHLY_LIMIT_INFINITE;
      s.documentsUploaded = userInfo.currentMonthlyFilesUploaded;
      s.documentLimit = limit;
      s.maxMonthlyPrompts = userInfo.maxMonthlyPrompts;
      s.currentMonthlyUserPromptsPerModel =
        userInfo.currentMonthlyUserPromptsPerModel;
      return s;
    });
  });
};

export const loginAction = async () => {
  UserStore.update((s) => {
    s.requestedAuth = true;
    return s;
  });
};

export const setUserHasLoaded = async (
  isAuthed: boolean,
  apiConfig: any,
  accountInfo: any,
) => {
  const agreedToTerms = await retrieveSessionValue('agreedToTerms');
  localStorage.setItem(
    LocalStorageKey.localAccountId,
    accountInfo?.localAccountId,
  );

  UserStore.update((s) => {
    s.isAuthed = isAuthed;
    s.apiConfig = apiConfig;
    s.userHasLoaded = true;
    s.userInfo = {
      name: accountInfo?.name || '',
      email: accountInfo?.username || '',
      initials: getInitials(accountInfo?.name || ''),
    };
    s.agreedToTerms = agreedToTerms === 'true';
    return s;
  });
  loadUserLimits();
  persistSessionValue('isAuthed', 'true');
};

export const logoutAction = async () => {
  persistSessionValue('isAuthed', null);

  UserStore.update((s) => {
    s.requestedAuth = false;
    s.apiConfig = null;
    s.isAuthed = false;
    s.currentSession = '';
    s.viewedSplash = false;
    return s;
  });

  await setAgreedToTerms(false);
};

export const getIsPersonalFilePending = async (fileId: string) => {
  try {
    const file = await api.getFileInfo(fileId);
    return hasFilesInProgress([file]);
  } catch (e) {
    logger.error('Could not fetch personal files', e);
  }
};

export const getPersonalFiles = async () => {
  try {
    const files = await api.getFileList();
    if (files && Array.isArray(files)) {
      UserStore.update((s) => {
        s.personalFiles = files;
        return s;
      });
    }
  } catch (e) {
    logger.error('Could not fetch personal files', e);
  }
};

export const getPersonalIndexes = async () => {
  try {
    const indexes = await api.getIndexList();
    if (indexes && Array.isArray(indexes)) {
      UserStore.update((s) => {
        s.personalIndexes = indexes;
        return s;
      });
    }
  } catch (e) {
    logger.error('Could not fetch personal indexes', e);
  }
};

export const markFilesForDeletion = async (fileIds: string[]) => {
  UserStore.update((s) => {
    s.personalFiles = s.personalFiles.map((file) => {
      if (fileIds.includes(file.id)) {
        file.processingStatus = 'deleting';
      }
      return file;
    });
    return s;
  });
};

export const setAgreedToTerms = async (agreedToTerms: boolean) => {
  persistSessionValue('agreedToTerms', agreedToTerms.toString());
  UserStore.update((s) => {
    s.agreedToTerms = agreedToTerms;
    return s;
  });
};

export const setViewedSplash = async (viewedSplash: boolean) => {
  persistSessionValue('viewedSplash', viewedSplash.toString());
  UserStore.update((s) => {
    s.viewedSplash = viewedSplash;
    return s;
  });
};

export const updateDocumentsRemaining = async () => {
  await loadUserLimits();
  const currentDocumentsRemaining = UserStore.getRawState().documentsRemaining;

  if (currentDocumentsRemaining === FILE_UPLOAD_MAXIMUM_WARNING) {
    const message = `You are ${FILE_UPLOAD_MAXIMUM_WARNING} uploads away from your limit.`;
    logger.info('User was warned about file limit');
    return message;
  }
  if (currentDocumentsRemaining === 0) {
    const message = 'You have reached your monthy upload limit.';
    logger.info('User was warned about hitting the file limit');
    return message;
  }
  return '';
};

(async () => {
  const isAuthed = await retrieveSessionValue('isAuthed');
  const agreedToTerms = await retrieveSessionValue('agreedToTerms');
  const viewedSplash = await retrieveSessionValue('viewedSplash');
  if (isAuthed === 'true') {
    UserStore.update((s) => {
      s.isAuthed = true;
      s.agreedToTerms = agreedToTerms === 'true';
      s.viewedSplash = viewedSplash === 'true';
      return s;
    });
  }
})();
