import { Tag } from 'ui/atoms';
import { MarkdownRenderer } from 'ui/molecules/MarkdownRenderer';
import PHIProtection from '../PHIProtection';

import './conversation-response-row.css';

const ConversationResponseRow = ({
  message,
  profileImg,
  isError = false,
  allowRetry = false,
  isPHIDetected = false,
  userConfirmedPHIAllowed = false,
}) => {
  const canDisplayMessage =
    !isPHIDetected || (isPHIDetected && userConfirmedPHIAllowed);
  const showPHIProtection = isPHIDetected && !userConfirmedPHIAllowed;

  return (
    <div className="message">
      <div className={`response${isError ? ' error' : ''}`}>
        <img src={profileImg} alt="profile-pic" />

        {showPHIProtection && <PHIProtection />}

        {canDisplayMessage && (
          <>
            {!isError && (
              <div className="generated-response-row">
                {userConfirmedPHIAllowed && (
                  <Tag>Generated using prompt that may contain PHI</Tag>
                )}
                <MarkdownRenderer text={message} />
              </div>
            )}
            {isError && (
              <span className="error-message">
                We're having a little trouble generating a response.{' '}
                {allowRetry && (
                  <span>Would you like to try regenerating that response?</span>
                )}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ConversationResponseRow;
