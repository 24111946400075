import { ConversationsApi } from 'aida-api-client';

import { AuthStore, updateAccessToken } from 'stores/auth';
import { NIL_UUID } from 'stores/user';
import { LLModel } from 'stores/metadata';
import { silentRequestBaseConfig } from './hooks/useSilentRequest';
import { logger } from 'services/logs/logger';
import { LocalStorageKey } from 'utils/localstorage';
import { isObjectEmpty } from 'utils/object-utils';

class ErrorWithDetail extends Error {
  additionalData: any;

  constructor(message: string, additionalData?: Object) {
    super(message);
    this.name = 'ErrorWithDetail';

    if (additionalData) {
      this.additionalData = additionalData;
    }
  }
}

export const throwResponseError = ({
  response,
  message,
  additionalData = {},
}: {
  response: Response;
  message?: string;
  additionalData?: { detail?: string; status?: number };
}) => {
  const status = response.status;
  const isClientSideError = status >= 400 && status < 500;
  const isServerSideError = status >= 500 && status < 600;

  if (message && !!additionalData) {
    throw new ErrorWithDetail(message);
  }

  let errorMessage = 'An unexpected error occurred. Please try again.';

  if (isClientSideError) {
    errorMessage = `[${status}] There was a problem processing your request.`;
  }

  if (isServerSideError) {
    errorMessage = `[${status}] An unexpected error has occured on our server.`;
  }

  if (additionalData?.detail) {
    errorMessage = additionalData.detail;
  }

  throw new ErrorWithDetail(errorMessage, additionalData);
};

export const apiConfig = {
  basePath: '',
  apiKey: '',
};

const ApiService = () => {
  let api: ConversationsApi | {} = {};

  const setApiConfig = async (newApiConfig: any) => {
    if (newApiConfig) {
      const token = await acquireFreshToken();
      api = new ConversationsApi({
        ...newApiConfig,
        apiKey: token,
      });
      Object.assign(apiConfig, { ...newApiConfig });
    }
  };

  const acquireFreshToken = async () => {
    const { MSALInstance, currentAccount } = AuthStore.getRawState();

    const silentRequest = {
      account: currentAccount,
      ...silentRequestBaseConfig,
    };

    const token = await MSALInstance.acquireTokenSilent(silentRequest)
      .then((response: any) => {
        updateAccessToken(response.accessToken);
        return response.accessToken;
      })
      .catch(async (error) => {
        if (error.name === 'InteractionRequiredAuthError') {
          await MSALInstance.acquireTokenRedirect(silentRequest);
        }

        logger.error(error);
      });

    return `Bearer ${token}`;
  };

  const fetchResource = async (url: string, config: any) => {
    const token = await acquireFreshToken();
    const sessionIdValue = localStorage.getItem(LocalStorageKey.sessionId);

    const urlWithParams = new URL(`${apiConfig?.basePath}${url}`);

    if (sessionIdValue && sessionIdValue !== NIL_UUID) {
      urlWithParams.searchParams.append('sessionId', sessionIdValue);
    }

    return await fetch(urlWithParams.toString(), {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token,
      },
    });
  };

  const getConversations = async ({ top = 0, skip = 0 }) => {
    let baseUrl = '/conversations';

    const params: string[] = [];

    if (top !== 0) {
      params.push(`$top=${top}`);
    }
    if (skip !== 0) {
      params.push(`$skip=${skip}`);
    }

    if (params.length > 0) {
      baseUrl += `?${params.join('&')}`;
    }

    const response = await fetchResource(baseUrl, {
      method: 'GET',
    });

    const data = await response.json();
    const totalCount = response?.headers?.get('x-total-count') ?? 0;

    if (!data) {
      throw new Error('Unable to fetch conversations');
    }

    return [data, totalCount];
  };

  const sendMessage = async (conversationId: string, metadata: any) => {
    const response = await fetchResource(
      `/conversations/${conversationId}/messages`,
      {
        method: 'POST',
        body: JSON.stringify(metadata),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throwResponseError({ response, message: 'Network response was not OK' });
    }

    const data = await response.json();

    if (!data) {
      throw new Error('Unable to post message');
    }

    return data;
  };

  const streamMessage = async (conversationId: string, messageId: string) => {
    const response = await fetchResource(
      `/conversations/${conversationId}/messages/${messageId}/streams`,
      {
        method: 'POST',
      },
    );

    if (!response.ok) {
      throwResponseError({ response, message: 'Network response was not OK' });
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('ReadableStream not available');
    }

    return reader;
  };

  const getMessageById = async (conversationId: string, messageId: string) => {
    const response = await fetchResource(
      `/conversations/${conversationId}/messages/${messageId}`,
      {
        method: 'GET',
      },
    );

    if (!response.ok) {
      throwResponseError({ response, message: 'Network response was not OK' });
    }

    const data = await response.json();

    if (!data) {
      throw new Error('Unable to post message');
    }

    return data;
  };

  const updateMessageMetadata = async ({
    conversationId,
    message,
    payload = {},
  }) => {
    if (isObjectEmpty(api)) {
      return;
    }

    const latestMessage = await getMessageById(
      conversationId,
      message.messageId,
    );
    const { datetimeLastUpdated } = latestMessage;
    const { messageId } = message;

    const patchedPayload = {
      textContent: message.message,
      ...payload,
    };

    const options = {
      method: 'PATCH',
      body: JSON.stringify(patchedPayload),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const encodedDatetimeLastUpdated =
        encodeURIComponent(datetimeLastUpdated);
      const response = await fetchResource(
        `/conversations/${conversationId}/messages/${messageId}?datetimeLastUpdated=${encodedDatetimeLastUpdated}`,
        options,
      );

      if (!response.ok) {
        throwResponseError({ response });
      }

      return await response.json();
    } catch {
      return;
    }
  };

  const getFileList = async () => {
    try {
      const filesResponse = await fetchResource(`/files`, {
        method: 'GET',
      });

      return await filesResponse.json();
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        logger.error('Unable to fetch files');
      }
      return [];
    }
  };

  const getFileInfo = async (fileId) => {
    try {
      const filesResponse = await fetchResource(`/files/${fileId}`, {
        method: 'GET',
      });

      return await filesResponse.json();
    } catch {
      logger.error(`Unable to fetch file info for ${fileId}`);
      return {};
    }
  };

  const deleteFile = async (fileId: string) => {
    const filesResponse = await fetchResource(`/files/${fileId}`, {
      method: 'DELETE',
    });

    return filesResponse;
  };

  const uploadFile = async (file: string, metadata: any) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('privacyLevel', metadata.accessLevel);
    formData.append('organizationId', metadata.organizationId);
    formData.append('productId', metadata.productId);

    const options = {
      method: 'POST',
      body: formData,
    };

    const response = await fetchResource(`/files`, options);

    if (!response.ok) {
      const message = await response.json();
      throwResponseError({ response, additionalData: message });
    }

    return response.json();
  };

  const updateFileMetadata = async (
    fileId: any,
    datetimeLastUpdated: any,
    metadata: any,
  ) => {
    if (isObjectEmpty(api)) {
      return;
    }

    const options = {
      method: 'PATCH',
      body: JSON.stringify(metadata),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      datetimeLastUpdated = encodeURIComponent(datetimeLastUpdated);
      const response = await fetchResource(
        `/files/${fileId}?datetimeLastUpdated=${datetimeLastUpdated}`,
        options,
      );

      if (!response.ok) {
        throwResponseError({ response });
      }

      return response;
    } catch {
      return;
    }
  };

  const getApiVersion = async () => {
    try {
      const response = await fetchResource(`/version`, {
        method: 'GET',
      });

      const { version } = await response.json();

      return version;
    } catch {
      return '0.0.0';
    }
  };

  const getGptModels = async () => {
    try {
      const response = await fetchResource(`/models`, {
        method: 'GET',
      });

      const gptModels = await response.json();

      return gptModels.map((gptModel: LLModel) => {
        return {
          id: gptModel.name,
          displayName: gptModel.displayName,
          name: gptModel.name,
          description: gptModel.description,
          maxTokensCount: gptModel.maxTokenCount,
          disabled: gptModel.modelType === 'DISABLED',
        };
      });
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        logger.error('Unable to fetch GPT models');
      }
      return [];
    }
  };

  const deleteConversationById = async (conversationId: string) => {
    await fetchResource(`/conversations/${conversationId}`, {
      method: 'DELETE',
    });
  };

  const getConversationById = async (conversationId: string) => {
    const response = await fetchResource(`/conversations/${conversationId}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throwResponseError({ response });
    }

    const data = await response.json();

    if (!data) {
      throw new Error('Unable to fetch conversations');
    }

    return data;
  };

  const updateConversationById = async (
    conversationId: string,
    datetimeLastUpdated: any,
    metadata: any,
  ) => {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(metadata),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      datetimeLastUpdated = encodeURIComponent(datetimeLastUpdated);
      const response = await fetchResource(
        `/conversations/${conversationId}?datetimeLastUpdated=${datetimeLastUpdated}`,
        options,
      );

      if (!response.ok) {
        throwResponseError({ response });
      }

      const data = await response.json();

      if (!data) {
        throw new Error(`Unable to fetch conversation ${conversationId}`);
      }

      return data;
    } catch {
      return;
    }
  };

  const getConversationMessagesById = async (conversationId: string) => {
    const response = await fetchResource(
      `/conversations/${conversationId}/messages`,
      {
        method: 'GET',
      },
    );

    if (!response.ok) {
      throwResponseError({ response });
    }

    const data = await response.json();

    if (!data) {
      throw new Error('Unable to fetch conversation messages');
    }

    return data;
  };

  const updateConversationMessageById = async (
    conversationId: string,
    messageId: string,
    datetimeLastUpdated: any,
    metadata: any,
  ) => {
    const options = {
      method: 'PATCH',
      body: JSON.stringify(metadata),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      datetimeLastUpdated = encodeURIComponent(datetimeLastUpdated);
      const response = await fetchResource(
        `/conversations/${conversationId}/messages/${messageId}?datetimeLastUpdated=${datetimeLastUpdated}`,
        options,
      );

      if (!response.ok) {
        throwResponseError({ response });
      }

      const data = await response.json();

      if (!data) {
        throw new Error(`Unable to update conversation message ${messageId}`);
      }

      return data;
    } catch {
      return;
    }
  };

  const getNewConversation = async () => {
    const response = await fetchResource(`/conversations`, {
      method: 'POST',
    });

    if (!response.ok) {
      throwResponseError({ response });
    }

    const data = await response.json();

    if (!data) {
      throw new Error('Cannot create new conversation');
    }

    return data;
  };

  const getUserLimits = async () => {
    try {
      const response = await fetchResource(`/user`, {
        method: 'GET',
      });

      const userInfo = await response.json();

      return userInfo;
    } catch {
      return {};
    }
  };

  const getIndexList = async () => {
    try {
      const indexResponse = await fetchResource(`/indexes`, {
        method: 'GET',
      });

      return await indexResponse.json();
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        logger.error('Unable to fetch indexes');
      }
      return [];
    }
  };

  return {
    acquireFreshToken,
    deleteConversationById,
    deleteFile,
    fetchResource,
    getApiVersion,
    getConversationById,
    getConversationMessagesById,
    getConversations,
    getMessageById,
    getFileInfo,
    getFileList,
    getGptModels,
    getNewConversation,
    sendMessage,
    setApiConfig,
    streamMessage,
    updateConversationById,
    updateConversationMessageById,
    updateFileMetadata,
    updateMessageMetadata,
    uploadFile,
    getUserLimits,
    getIndexList,
  };
};

export default ApiService();
