import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, GoldieLogo, StrykerLogo } from 'ui';

import './noaccess.css';

const GOLDIE_TRAINING_LINK = '#';
const SERVICE_NOW_LINK =
  'https://strykercorp.service-now.com/sp?id=sc_category&sys_id=b2a9a83493c01e502c423ecdfaba10b0&catalog_id=e0d08b13c3330100c8b837659bba8fb4';

const NoAccess = () => {
  const navigate = useNavigate();

  const proceed = () => {
    navigate('/login');
  };
  return (
    <main data-testid="noaccess-container" className="noaccess-page">
      <article>
        <StrykerLogo />
        <GoldieLogo />
        <p>
          You do not have access to Goldie. Only full-time, non-contractor,
          non-sales employees based in the U.S. can access this system.
        </p>
        <p>
          To request access, please complete the required{' '}
          <a data-testid="goldie-training-link" href={GOLDIE_TRAINING_LINK}>
            Goldie training
          </a>
          .
        </p>
        <p>
          After successful training completion, submit your access request via{' '}
          <a data-testid="service-now-link" href={SERVICE_NOW_LINK}>
            ServiceNow
          </a>
          .
        </p>
        <p>
          <Button
            data-testid="noaccess-proceed"
            className="proceed"
            onClick={() => proceed()}
          >
            Go back to sign-in page
          </Button>
        </p>
      </article>
    </main>
  );
};

export default NoAccess;
